export const SELECT_PROJECT = 'select_project',
  //** PROJECT ACTIONS **//
  POST_CREATE_PROJECT = 'post_create_project',
  CREATE_PROJECT_SUCCESS = 'create_project_success',
  CREATE_PROJECT_FAILURE = 'create_project_failure',
  GET_PROJECTS = 'get_projects',
  GET_PROJECTS_SUCCESS = 'get_projects_success',
  GET_PROJECTS_FAILURE = 'get_projects_failure',
  GET_PROJECT = 'get_project',
  GET_PROJECT_SUCCESS = 'get_project_success',
  GET_PROJECT_FAILURE = 'get_project_failure',
  UPDATE_PROJECT = 'update_project',
  UPDATE_PROJECT_SUCCESS = 'update_project_success',
  UPDATE_PROJECT_FAILURE = 'update_project_failure',
  DELETE_PROJECT = 'delete_project',
  DELETE_PROJECT_SUCCESS = 'delete_project_success',
  DELETE_PROJECT_FAILURE = 'delete_project_failure',
  SET_PROJECT_STATUS = 'set_project_status',
  SET_PROJECT_STATUS_SUCCESS = 'set_project_status_success',
  SET_PROJECT_STATUS_FAILURE = 'set_project_status_failure',
  DELETE_ALL_REMOVED_PROJECTS = 'delete_all_removed_projects',
  DELETE_ALL_REMOVED_PROJECTS_SUCCESS = 'delete_all_removed_projects_success',
  DELETE_ALL_REMOVED_PROJECTS_FAILURE = 'delete_all_removed_projects_failure',
  SET_PROJECT_STATUS_VIEW = 'set_project_status_view',
  SEARCH_PROJECTS = 'search_projects',
  SEARCH_PROJECTS_SUCCESS = 'search_projects_success',
  SEARCH_PROJECTS_FAILURE = 'search_projects_failure',
  /** WORK ACTIONS */
  START_WORK = 'start_work',
  START_WORK_SUCCESS = 'start_work_success',
  START_WORK_FAILURE = 'start_work_failure',
  // STOP_WORK = 'stop_work',
  CANCEL_WORK = 'cancel_work',
  CANCEL_WORK_SUCCESS = 'cancel_work_success',
  CANCEL_WORK_FAILURE = 'cancel_work_failure',
  CREATE_WORK = 'create_work',
  CREATE_WORK_SUCCESS = 'create_work_success',
  CREATE_WORK_FAILURE = 'create_work_failure',
  UPDATE_WORK = 'update_work',
  UPDATE_WORK_SUCCESS = 'update_work_success',
  UPDATE_WORK_FAILURE = 'update_work_failure',
  REMOVE_WORK = 'remove_work',
  REMOVE_WORK_SUCCESS = 'remove_work_success',
  REMOVE_WORK_FAILURE = 'remove_work_failure',
  GET_WORK_BY_INTERVAL = 'get_work_by_interval',
  GET_WORK_BY_INTERVAL_SUCCESS = 'get_work_by_interval_success',
  GET_WORK_BY_INTERVAL_FAILURE = 'get_work_by_interval_failure',
  GET_ALL_WORK = 'get_all_work',
  GET_ALL_WORK_SUCCESS = 'get_all_work_success',
  GET_ALL_WORK_FAILURE = 'get_all_work_failure',
  //** AUTH ACTIONS **//
  POST_REGISTRATION = 'post_registration',
  POST_REGISTRATION_SUCCESS = 'post_registration_success',
  POST_REGISTRATION_FAILURE = 'post_registration_failure',
  POST_LOGIN = 'post_login',
  POST_LOGIN_SUCCESS = 'post_login_success',
  POST_LOGIN_FAILURE = 'post_login_failure',
  POST_LOGOUT = 'post_logout',
  POST_LOGOUT_SUCCESS = 'post_logout_success',
  POST_LOGOUT_FAILURE = 'post_logout_failure',
  GET_USER_INFO = 'get_user_info',
  GET_USER_INFO_SUCCESS = 'get_user_info_success',
  GET_USER_INFO_FAILURE = 'get_user_info_failure',
  RESEND_VERIFICATION = 'resend_verification',
  RESEND_VERIFICATION_SUCCESS = 'resend_verification_success',
  RESEND_VERIFICATION_FAILURE = 'resend_verification_failure',
  // ** MESSAGE ACTIONS ** //
  DISMISS_MESSAGE = 'dismiss_message',
  // ** UTILITY ACTIONS ** //
  SET_NEW_TOKEN = 'set_new_token',
  VERIFICATION_FAILURE = 'verification_failure'; // Message action?
